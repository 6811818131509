@import './components/navbar/index.scss';
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Libre+Bodoni:ital,wght@0,400..700;1,400..700&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,100;1,300;1,400;1,500;1,700;1,900&display=swap');

body {
    font-family: "Roboto", sans-serif;
    font-weight: 400;
    font-style: normal;
    margin: 0;
    width: 0 auto;
    background-color: #000;
}

.libre-bodoni {
    font-family: "Libre Bodoni", serif!important;
    font-optical-sizing: auto;
    font-weight: 800;
    font-style: normal;
}

.adjusted-img {
    width: 100%; /* Asegura que la imagen ocupe el ancho completo */
    height: 100%; /* Asegura que la imagen ocupe la altura completa */
    object-fit: cover; /* Recorta la imagen para llenar el espacio sin deformarla */
    object-position: center; /* Centra la imagen dentro del contenedor */
}

.image-max {
    width: 765px;
    height: 765px;
}

.image-mini {
    width: 494px;
    height: 494px;
}

.image-xs {
    width: 494px;
    height: 251px;
}

.escribinos-wp {
    color: #fff;
    text-decoration: none;
    font-weight: 400;
    font-size: 28px;
    text-align: center;
    display: block;

    img {
        height: 50px;
        width: 50px;
    }
}

.content-center {
    text-align: center;
    margin: 40px auto;
    color: #fff;
}

@media (max-width: 768px) {
    .image-max,
    .image-mini,
    .image-xs,
    .adjusted-img {
        max-width: 100%;
        width: 100%;
        margin: 10px 0;
   }
}