.carousel {
  position: relative;
  width: 100%;
  max-width: 100%;
  margin: auto;
  overflow: hidden;
  display: flex;
  align-items: center;
}

.color-0 {
  height: 100vh;
  background-image: url('../../assets/images/Amados2.jpg');
  background-size: cover;
  background-position: center;
}
.color-1 {
  height: 100vh;
  background-image: url('../../assets/images/Amados1.jpg');
  background-size: cover;
  background-position: center;
}
.color-2 {
  height: 100vh;
  background-image: url('../../assets/images/Amados3.jpg');
  background-size: cover;
  background-position: center;
}

.carousel-content {
  margin: 0 auto;
  font-style: normal;
  width: 100%;
  position: relative;
  top: 0;
  background-color: rgba(0, 0, 0, 0.3);
  color: #fff;
  height: 100vh;
  text-align: center;
}

.content-image {
  margin-top: 35vh;
  text-shadow: 1px 1px 15px rgba(0, 0, 0, 8);

  h2.carrousel-title {
    font-size: 3rem;
  }

  .carrousel-subtitle {
    font-size: 20px;
    width: 750px;
    margin: 0 auto;
  }

  .carrousel-footer {
    margin-top: 35vh;
    display: block;
  }
}

.carousel-button {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  padding: 10px;
  cursor: pointer;
  z-index: 1;
}

.carousel-button.prev {
  left: 10px;
}

.carousel-button.next {
  right: 10px;
}


@media (max-width: 768px) {
  .carrousel-subtitle {
    margin: 0 auto;
    max-width: 85%;
    width: 85%!important;
  }
}