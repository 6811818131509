footer {
  background-color: #000;
  color: #fff;
  
  aside {
    display: flex;
    justify-content: space-around;
    margin-bottom: 50px;

    ul {
      display: block;
      list-style: none;
      li {
        margin-top: 10px;
      }
    }
  }

  p {
    text-align: center;
    flex-grow: 1;
  }
}

.footer-link {
  color: #fff;
  text-decoration: none;
  cursor: pointer;
  margin: 10px;

  a {
    color: #fff;
    text-decoration: none;
    cursor: pointer;
    margin: 10px;     
  }
}

@media (max-width: 768px) {
  aside {
      display: flex;
      flex-direction: column;
  }
}