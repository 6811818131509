.whatsapp-icon {
    width: 80px;
    height: 80px;
    position: fixed;
    bottom: 20px;
    right: 20px;
    border-radius: 50%;
    box-shadow: 2px 2px 4px rgba(0, 0, 0, 0.3);

    a {
        width: 80px;
        height: 80px;

        img {
            width: 80px;
            height: 80px;
        }
    }
}