.navbar {
    position: fixed;
    height: 75px;
    padding: 1rem 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    top: 0;
    width: 100%;
    z-index: 999;
    backdrop-filter: blur(10px);
    box-shadow: 1px 1px 5px 0px rgba(0, 0, 0, 0.5);

    ul.nav-menu-desktop {
        list-style: none;
        padding: 0;
        margin: 0;

        li.nav-menu-desktop {
            color: #fff;
            font-size: 15px;

            a {
                text-decoration: none;
                color: #fff;
            }

            button {
                color: #fff;
                border: none;
                background: none;
            }
        }
    }
}

.submenu-desktop {
    position: absolute;
    list-style: none;
    display: flex;
    flex-direction: column;
    padding: 10px;
    right: 180px;
    background-color: rgba(0, 0, 0, 0.5);
    width: 130px;
    top: 75px;
    color: #fff;

    li {
        text-align: left;
    }
}

.link-r-d {
    a {
        color: #fff;
        text-decoration: none;
    }
}

li.nav-menu-desktop:hover {
    a {
        color: cornflowerblue!important;
    }
    button {
        color: cornflowerblue!important;
    }
}

.link-r-d:hover {
    a {
        color: cornflowerblue!important;
    }
}

.submenu-mobile {
    position: absolute;
    list-style: none;
    display: flex;
    flex-direction: column;
    background-color: rgba(0, 0, 0, 0.9);
    width: 100%;
    margin: 10px auto;
    top: 65px;
    left: 0;
    height: 100vh;

    li {
        padding: 20px 0 10px;
    }
}

.scrolled {
    background-color: rgba(0, 0, 0, 0.5);
}

.show {
    display: block;
}

.logo {
    display: inline-block;
    width: 135px;
}

.menu-desktop {
    display: inline-block;
    text-align: right;
}

@media (min-width: 768px) {
    nav ul {
        display: flex;
        flex-direction: row;
        gap: 2rem;
    }

    nav button.menu-mobile {
        display: none;
    }
}

@media (max-width: 768px) {
    nav ul {
        display: flex;
        flex-direction: row;
        gap: 1rem;
    }

    .menu-desktop {
        display: none;
    }
}

button.menu-mobile {
    background: none;
    border: none;
    cursor: pointer;

    svg {
        width: 35px;;
        height: 35px;;
    }
}