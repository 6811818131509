article {
    svg {
        opacity: 0.8;
        background: rgb(215 215 255);
        border-radius: 50%;
        height: 100px;
        width: 100px;
        padding: 20px;
    }
}

.components-text {
    display: flex;
    align-items: center;
    justify-content: space-around;
    font-size: 18px;
    
    article {
        font-size: 16px;
        font-weight: 300;
        height: 250px;
        max-height: 250px;
        padding: 30px 0;
        text-align: center;
        width: 400px;
        color:#fff;
        p {
            font-size: larger;
            margin: 1rem 0;
        }
    }
}

h2.title-deals {
    text-align: center;
    padding: 50px 0;
    color: #fff;
    position: relative;
}

h2.title-deals::before {
    content: "";
    display: block;
    width: 105px;
    height: 3px;
    background-color: #3A58B0;
    position: absolute;
    bottom: 40px;
    left: 46%;

    content: "";
    display: block;
    width: 105px;
    height: 3px;
    background-color: #3A58B0;
    position: absolute;
    bottom: 40px; /* Ajusta la distancia entre el texto y la línea */
    left: 50%; /* Posición horizontal centrada */
    transform: translateX(-50%);
}

@media (max-width: 768px) {
    .components-text {
        display: flex;
        flex-direction: column; 
    }
}