.grid-container {
    position: relative;
    max-width: 100%;
    width: 100%;
    display: flex;
    justify-content: space-evenly;
    margin-bottom: 50px;

    img {
        display: block;
    }
}

.grid-vertical {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.grid-text-link {
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0%;
    left: 0%;
    background-color: rgba(0, 0, 0, 0.3);
    transition: opacity 0.2s;
    display: flex;
    align-items: center;
    justify-content: center;

    a {
        text-decoration: none;
        display: block;
        font-size: 34px;
        color: #fff;
        text-align: center;
        text-shadow: 1px 1px 15px rgba(0, 0, 0, 8);
    }
}

@media (max-width: 768px) {
    .grid-container {
        display: flex;
        flex-direction: column;
    }
    
    img {
        width: 100%;
    }
}